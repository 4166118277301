<template>
  <div>
    <v-row dense>
      <v-col sm="2" xl="2" md="2" lg="2" class=" flex-justify-end flex-center">
        是否需要工时计划：
      </v-col>
      <v-col sm="8" xl="8" md="8" lg="8">
        <form-item
          v-model="info.MANHOUR_PLAN_OPEN_FLAG"
          class=" flex-0 flex-shrink-0"
          :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
          style="width: 250px"
          dense
          type="radio"
          @input="$forceUpdate()"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col sm="2" xl="2" md="2" lg="2" class=" flex-justify-end flex-center">
        工时是否需要审核：
      </v-col>
      <v-col sm="8" xl="8" md="8" lg="8">
        <form-item
          v-model="info.MANHOUR_AUDIT_FLAG"
          class=" flex-0 flex-shrink-0"
          :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
          style="width: 250px"
          dense
          type="radio"
          @input="$forceUpdate()"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col sm="2" xl="2" md="2" lg="2" class=" flex-justify-end flex-center">
        是否显示中心名称：
      </v-col>
      <v-col sm="8" xl="8" md="8" lg="8">
        <form-item
          v-model="info.MANHOUR_ENTER_PARTNER_FLAG"
          class=" flex-0 flex-shrink-0"
          :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
          style="width: 250px"
          dense
          type="radio"
          @input="$forceUpdate()"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col sm="2" xl="2" md="2" lg="2" class=" flex-justify-end flex-center">
        是否显示明日工作计划：
      </v-col>
      <v-col sm="8" xl="8" md="8" lg="8">
        <form-item
          v-model="info.MANHOUR_DISPLAY_NEXTDAY_PLAN_FLAG"
          class=" flex-0 flex-shrink-0"
          :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
          style="width: 250px"
          dense
          type="radio"
          @input="$forceUpdate()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="2" xl="2" md="2" lg="2" />
      <v-col sm="8" xl="8" md="8" lg="8">
        <v-btn small color="primary" depressed min-width="120" class="mr-2" height="30" style="padding:0 8px" @click="saveProgressLimit">保存</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      initInfo: {},
      info: {
        MANHOUR_PLAN_OPEN_FLAG: '',
        MANHOUR_AUDIT_FLAG: '',
        MANHOUR_ENTER_PARTNER_FLAG: '',
        MANHOUR_DISPLAY_NEXTDAY_PLAN_FLAG: ''
      }
    }
  },
  mounted() {
    this.getDispatchLimit()
  },
  methods: {
    // 获取
    getDispatchLimit() {
      console.log('进来')
      this.$http.get(this.$api.settingGetManhour, { data: {}, type: 'application/x-www-form-urlencoded' }).then(res => {
        this.initInfo = this.$deepClone(res.result || {})
        this.info = res.result || {}
      }).catch(() => {
        this.info = {}
      })
    },
    // 确认保存
    saveProgressLimit() {
      this.btnLoading = true
      this.$http.post(this.$api.settingSaveManhour, { data: { ...this.info }}).then(res => {
        console.log('保存工时', res)
        this.$message.success(res.result || res.message)
        this.btnLoading = false
      }).catch(() => {
        this.btnLoading = false
        this.info = this.$deepClone(this.initInfo)
      })
    }
  }
}
</script>

<style>

</style>
